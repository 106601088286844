import Entstehung from "./body/entstehung";
import Projekt from "./body/projekt";
import Zusammenarbeit from "./body/zusammenarbeit";
import Video from "./body/video";
import Team from "./body/team";

function Body() {
  return (
    <div class="shadow-md">
      <Zusammenarbeit></Zusammenarbeit>
      <Projekt></Projekt>
      <Video url="https://togetherfit.s3.eu-west-3.amazonaws.com/CP+Kurz+FINAL+NR.mp4"></Video>
      <Entstehung></Entstehung>
      <Video url="https://togetherfit.s3.eu-west-3.amazonaws.com/CP+Lang+FINAL+NR.mp4"></Video>
      <Team></Team>
      <div class="clear-left"></div>
    </div>
  );
}

export default Body;
