import image2 from "../../image2.png";
import image3 from "../../image3.png";
function Entstehung() {
  return (
    <div class="max-w-6xl mx-auto md:px-6 px-4 clear-left">
      <div class="md:py-10 py-6">
        <div class="mx-auto mx-4 text-left ">
          <h2>
            Entstehung togetherfit: Die "Funktionelle Trainingsintervention für
            Jugendliche mit Cerebralparese-Studie"
          </h2>
          <p>
            <img
              class="h-40 md:h-full object-contain md:object-scale-down float-left rounded m-3 shadow-2xl"
              src={image2}
              alt="image2"
            ></img>
            Das <i>Togetherfit</i> Programm ist eine Weiterführung der laufenden
            CP-FIT Studie des Universitäts-Kinderspitals beider Basel (Dr. med.
            Michèle Kläusler, Prof. Dr. med. Elke Viehweger) und dem Department
            für Sport, Bewegung und Gesundheit (MSc. Alice Minghetti, Dr. Ralph
            Roth, Dr. Martin Keller) für Jugendliche mit Cerebralparese. Die
            CP-FIT Studie zielt darauf ab, einen neuartigen und inklusiven
            Ansatz in der Art und Weise, wie die Therapie für Jugendliche mit
            Cerebralparese durchgeführt wird, zu bieten. Die CP-FIT Studie
            untersucht die Auswirkungen einer funktionellen
            Trainingsintervention auf die motorischen Fähigkeiten und die
            motorische Kapazität. Das <i>Togetherfit</i> Programm ist eine
            Fortführung und ein Produkt der CP-FIT-Studie. Basierend auf den
            Erkenntnissen der Studie wird das Programm adaptiert und angepasst,
            wobei der primäre Fokus auf den Transfereffekten der
            Trainingseinheiten in den Alltag liegt, einschliesslich körperlicher
            Funktionsfähigkeit, Partizipation, Aktivitätsverhalten und
            Lebensqualität. <i>Togetherfit</i>
            bietet Jugendlichen und jungen Erwachsenen mit körperlichen
            <img
              class="h-40 md:h-full object-contain md:object-scale-down float-right rounded m-3 shadow-2xl"
              src={image3}
              alt="image3"
            ></img>
            Behinderungen ein langfristiges, wissenschaftlich begleitetes
            Sportprogramm in einem sozialen und nicht-klinischen Umfeld.
            <br></br>
            <br></br>
            Das Training (60 min.) ist auf jeweils 3 Monate angesetzt und wird
            2x/Woche im CrossFit Basel Downtown (Byfangweg 1a, 4051 Basel)
            durchgeführt. Geleitet wird das Training durch ein junges Team mit
            hoher Expertise im funktionellen Training. Das Team besteht aus
            ausgebildeten Sportwissenschaftlern und CrossFit-Experten mit
            diversen Zusatzausbildungen wie Crossfit-Kids und Adaptive Crossfit
            (Crossfit für Menschen mit körperlicher Beeinträchtigung).
            Zusätzlich wird das Training von einer ärztlichen Fachperson
            begleitet. Bei Interesse an einer Studienteilnahme oder an ein
            Schnuppertraining könnt ihr euch hier melden:{" "}
            <a href="mailto:michele.klaeusler@ukbb.ch">
              michele.klaeusler@ukbb.ch
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Entstehung;
