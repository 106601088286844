import alice from "../../alice.png";
import michele from "../../michele.png";
import dominik from "../../dominik.jpeg";
import ramon from "../../Ramon Gysin.jpg";

function Team() {
  return (
    <div class="max-w-6xl mx-auto md:px-6 px-4 clear-right">
      <div class="md:py-10 py-6">
        <div class="mx-auto mx-4 text-left ">
          <h2>Team</h2>
          <div class="my-5 grid grid-cols-2 md:grid-cols-3 gap-5 justify-items-stretch">
            <div class="max-w-sm rounded overflow-hidden shadow-lg">
              <div class="px-6 py-4">
                <img class="w-full" src={michele} alt="Michèle"></img>
                <div class="font-bold text-xl my-2">Projektleitung</div>
                <div class="font-bold text-xl mb-2">Michèle Kläusler</div>
                <p class="text-gray-700 text-base">
                  Doctor of Medicine<br></br>
                  Stv. Oberärztin Neuroorthopädie UKBB<br></br>
                  CrossFit Level 1 Trainerin<br></br>
                </p>
              </div>
            </div>
            <div class="max-w-sm rounded overflow-hidden shadow-lg">
              <div class="px-6 py-4">
                <img class="w-full" src={alice} alt="Alice"></img>
                <div class="font-bold text-xl my-2">Projektleitung</div>
                <div class="font-bold text-xl mb-2">Alice Minghetti </div>
                <p class="text-gray-700 text-base">
                  MSc Sports and Sportscience<br></br>
                  PhD Candidate in Training and Movement Science<br></br>
                  CrossFit Level 2 Trainerin<br></br>
                  CrossFit Kids<br></br>
                </p>
              </div>
            </div>
            <div class="max-w-sm rounded overflow-hidden shadow-lg">
              <div class="px-6 py-4">
                <img class="w-full" src={ramon} alt="Ramon"></img>
                <div class="font-bold text-xl my-2">Coach</div>
                <div class="font-bold text-xl mb-2">Ramon Gysin</div>
                <p class="text-gray-700 text-base">
                  Owner CrossFit Basel<br></br>
                  CrossFit Level 3 Trainer<br></br>
                </p>
              </div>
            </div>
            <div class="max-w-sm rounded overflow-hidden shadow-lg">
              <div class="px-6 py-4">
                <img class="w-full" src={dominik} alt="Dominik"></img>
                <div class="font-bold text-xl my-2">Coach</div>
                <div class="font-bold text-xl mb-2">Dominik Buchmann</div>
                <p class="text-gray-700 text-base">
                  Master Student DSBG<br></br>
                  CrossFit Level 2 Trainer<br></br>
                </p>
              </div>
            </div>
            <div class="max-w-sm rounded overflow-hidden shadow-lg">
              <div class="px-6 py-4">
                <div class="font-bold text-xl my-2">Coach</div>
                <div class="font-bold text-xl mb-2">Nico Lerch</div>
                <p class="text-gray-700 text-base">
                  CrossFit Level 2 Trainer<br></br>
                </p>
              </div>
            </div>

            <div class="max-w-sm rounded overflow-hidden shadow-lg">
              <div class="px-6 py-4">
                <div class="font-bold text-xl my-2">
                  Wissenschaftliche Unterstützung
                </div>
                <div class="font-bold text-xl mb-2">
                  Prof. Dr. Elke Viehweger
                </div>
                <p class="text-gray-700 text-base">
                  Co-Chefärztin Kinderorthopädie UKBB<br></br>
                  Leiterin Neuroorthopädie und Ganglabor UKBB<br></br>
                </p>
              </div>
            </div>
            <div class="max-w-sm rounded overflow-hidden shadow-lg">
              <div class="px-6 py-4">
                <div class="font-bold text-xl my-2">
                  Wissenschaftliche Unterstützung
                </div>
                <div class="font-bold text-xl mb-2">Dr. Martin Keller</div>
                <p class="text-gray-700 text-base">
                  Wissenschaftlicher Mitarbeiter DSBG<br></br>
                </p>
              </div>
            </div>
            <div class="max-w-sm rounded overflow-hidden shadow-lg">
              <div class="px-6 py-4">
                <div class="font-bold text-xl my-2">
                  Wissenschaftliche Unterstützung
                </div>
                <div class="font-bold text-xl mb-2">Dr. Ralph Roth</div>
                <p class="text-gray-700 text-base">
                  Wissenschaftlicher Mitarbeiter DSBG<br></br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
