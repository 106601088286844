import logo from "../logo.svg";
function Header() {
  return (
    <div>
      <header class="bg-gradient-to-r from-c_1-400 via-c_2-500 to-c_3-500 shadow-2xl">
        <div class="container px-6 py-6 md:py-16 mx-auto flex justify-center">
          <div>
            <img class="h-16" src={logo} alt="logo"></img>
          </div>
        </div>

        <svg
          className="bla"
          viewBox="0 0 1577 190"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform="matrix(1,0,0,1,-517,-2794)">
            <g transform="matrix(1,0,0,1,-23.624,-70.8661)">
              <g>
                <g transform="matrix(1,0,0,0.412331,23.6214,1705.28)">
                  <path
                    class="fill-current text-c_1-500"
                    d="M472.441,3070.87L472.441,3307.09C472.441,3307.09 2127.69,3317.86 2125.98,3307.09C2124.28,3296.31 2125.99,2796.27 2125.99,2796.27C2125.99,2796.27 1900.79,2972.08 1795.28,3025.43C1398.97,3225.8 1273.04,2894.43 1039.37,2856.37C742.683,2808.04 517.933,2931.47 472.441,3070.87"
                  />
                </g>
                <g transform="matrix(1,0,0,0.412331,23.6227,1752.52)">
                  <path
                    class="fill-current text-c_1-600"
                    d="M472.441,3070.87L472.441,3307.09C472.441,3307.09 2127.69,3317.86 2125.98,3307.09C2124.28,3296.31 2125.99,2796.27 2125.99,2796.27C2125.99,2796.27 1902.69,3003.8 1795.28,3025.43C1401.46,3104.73 1273.76,2958.36 1086.62,2910.85C778.275,2832.57 517.933,2931.47 472.441,3070.87"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </header>
    </div>
  );
}

export default Header;
