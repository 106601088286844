import React, { Component } from "react";
import ReactPlayer from "react-player";
class Video extends Component {
  render() {
    return (
      <div class="max-w-6xl mx-auto md:px-6 px-2 clear-left">
        <div class="md:py-6 py-2">
          <div class="mx-2 md:mx-12">
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player fixed-bottom"
                url={this.props.url}
                width="100%"
                height="100%"
                controls={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Video;
