import image1 from "../../image1.png";
function Projekt() {
  return (
    <div class="max-w-6xl mx-auto md:px-6 px-4 ">
      <div class="md:py-10 py-6">
        <div class="mx-auto mx-4 text-left ">
          <h2>Um was geht es?</h2>
          <p>
            <i>Togetherfit</i> ist ein Trainingsangebot für Jugendliche und
            Erwachsene mit körperlichen und/oder geistigen Einschränkungen und
            zielt darauf ab, die motorische Kapazität sowie Fähigkeiten zu
            verbessern. Das Trainingsprogramm steigert die Kraft, die Ausdauer,
            <img
              class="h-40 md:h-full object-contain md:object-scale-down float-right rounded m-3 shadow-2xl"
              src={image1}
              alt="image1"
            ></img>
            die Koordination und trägt dazu bei, die Leistungsfähigkeit sowie
            Lebensqualität zu verbessern. Das Trainingsprogramm wird von
            Experten erstellt sowie angeleitet und wird im Gruppensetting
            durchgeführt.
            <br></br>
            <br></br>
            Das Training beinhaltet funktionelle Bewegungen und Übungen, welche
            fünf Merkmale erfüllen:
            <ul class="list-decimal list-inside">
              <li>natürlich, d.h. sie kommen im Alltag vor</li>
              <li>
                universelle motorische Rekrutierungsmuster, d.h. es besteht ein
                hoher Transfereffekt zwischen den Übungen
              </li>
              <li>essentiell, d.h. Bewegungen, die im Alltag notwendig sind</li>
              <li>sicher und</li>
              <li>
                <span>
                  zusammengesetzt aber irreduzibel, d.h. mehrgelenkige Übungen,
                  die nicht durch eingelenkige Bewegungen reproduziert werden
                  können
                </span>
              </li>
            </ul>
            Die Übungen werden dabei von ausgebildeten Trainern an die
            individuellen Fähigkeiten und an das Niveau der Teilnehmenden
            angepasst. <br></br>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Projekt;
