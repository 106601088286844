import MBF_Foundation_logo from "../MBF_Foundation_logo.png";
import ortho_logo from "../ortho_logo.jpg";
import ederer_stiftung_logo from "../ederer_stiftung_logo.jpg";
import stiftung_cerebral_logo from "../stiftung_cerebral_logo.jpg";

function Footer() {
  return (
    <div class="max-w-6xl mx-auto md:px-6 px-4 md:py-10 py-6 ">
      <div class="flex flex-wrap justify-center">
        <div>
          <img
            className="footer"
            src={MBF_Foundation_logo}
            alt="MBF_Foundation_logo"
          ></img>
        </div>
        <div>
          <img className="footer" src={ortho_logo} alt="ortho_logo"></img>
        </div>
        <div>
          <img
            className="footer"
            src={ederer_stiftung_logo}
            alt="ederer_stiftung_logo"
          ></img>
        </div>
        <div>
          <img
            className="footer"
            src={stiftung_cerebral_logo}
            alt="stiftung_cerebral_logo"
          ></img>
        </div>
      </div>
    </div>
  );
}

export default Footer;
