import logo_dsbg from "../../logo_dsbg.jpg";
import logo_ukbb from "../../logo_ukbb.jpg";
import logo_crossfit from "../../logo_crossfit.png";
function Zusammenarbeit() {
  return (
    <div class="max-w-6xl mx-auto md:px-6 px-4 ">
      <div class="md:py-10 py-6">
        <div class="mx-auto mx-4 text-left ">
          <h2>Ein Projekt von</h2>
          <div class="max-w-6xl mx-auto  py-6 md:py-10 ">
            <div class="flex flex-wrap justify-center">
              <div>
                <img
                  className="zusammenarbeit_logo"
                  src={logo_dsbg}
                  alt="logo_dsbg"
                ></img>
              </div>
              <div>
                <img
                  className="zusammenarbeit_logo"
                  src={logo_ukbb}
                  alt="logo_ukbb"
                ></img>
              </div>
              <div>
                <img
                  className="zusammenarbeit_logo"
                  src={logo_crossfit}
                  alt="logo_crossfit"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Zusammenarbeit;
